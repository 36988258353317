import React from 'react'

const Hint = ({ hints }) => {
    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            <div className='text-black font-semibold py-2 text-lg'> Hint </div>
            {
                hints?.length > 0 ?
                    hints?.map((hint) => (
                        <div className='text-black font-semibold text-sm opacity-[0.7]'>
                            <span className='text-black font-bold text-lg'>• </span>
                            {hint}
                        </div>
                    ))
                    :
                    <div className='text-black font-semibold text-sm opacity-[0.7]'>
                        You have written a correct code
                    </div>
            }
        </div>
    )
}

export default Hint