import React, { useContext, useEffect, useState } from 'react';
import './VideoComponent.css';
import thumbnail from '../../Assets/thumbnail.png'
import { ReactComponent as Down_Icon } from '../../Assets/Down_Icon.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import '../../Fonts/Figtree-VariableFont_wght.ttf'
import ReactPlayer from 'react-player';
import { CircularProgress, Collapse } from '@mui/material';
import { GeneralContext } from '../../Context';
import References from '../References/References';
import '../../Common/Common.css'
import { API } from '../../Config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function VideoComponent({ setExpandCodeEditorComponent, collapsedVideoSize, startNextVideo, setVideoSummary, handleVideoComplete, handleVideoChange, selectedVideo, videoLoading, expandVideoComponent, setExpandVideoComponent }) {

    const { videoDetails, selectedTopic, setStudentInfo } = useContext(GeneralContext);
    const [toggle, setToggle] = useState('videos')
    const [hasReachedNinetyPercent, setHasReachedNinetyPercent] = useState(false);
    const [referenceMaterial, setReferenceMaterial] = useState();

    const navigate = useNavigate()

    const getReferences = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            const { data } = await axios.post(`${API}/material/materials-by-id`, {
                material_ids: selectedTopic?.materials
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            setReferenceMaterial(data?.data)
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    navigate('/login')
                }
            }
            console.log(e.message)
        }
    }

    useEffect(() => {
        setTimeout(() => {
            setHasReachedNinetyPercent(false)
        }, 4000);
    }, [selectedVideo])

    useEffect(() => {
        if (selectedTopic?.materials?.length > 0) {
            getReferences()
        }
    }, [])

    useEffect(() => {
        if (expandVideoComponent === false && selectedVideo) {
            setToggle('now_playing')
        }
        else {
            setToggle('videos')
        }
    }, [expandVideoComponent])

    return (
        <Collapse className={` w-full ml-5 rounded-[8px] ${collapsedVideoSize === '50px' ? "collapse-container-video" : ""}`} orientation="vertical" in={expandVideoComponent} collapsedSize={collapsedVideoSize} timeout={900}>
            {/* <Collapse className='collapse-container-video w-full ml-5 rounded-[8px]' orientation="vertical" in={expandVideoComponent} collapsedSize={"50px"} timeout={900}> */}
            <div className="video-clip-container" style={{ height: '90vh' }}>
                <div className="w-full flex gap-5 items-center px-2 mb-1">
                    {
                        !expandVideoComponent && selectedVideo &&
                        <button
                            className={`text-[#AEAED4] uppercase text-xs font-semibold flex items-center gap-1 py-2`}
                            style={{ borderBottom: `${toggle === "now_playing" ? "1px solid #7F7FFF" : "1px solid #050519"}` }}
                            onClick={() => { 
                                setToggle('now_playing')
                                setExpandCodeEditorComponent(true)
                             }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                <path d="M8.10231 4.69271L5.33308 2.84656C5.26355 2.80017 5.18274 2.77353 5.09926 2.76949C5.01578 2.76545 4.93277 2.78415 4.85908 2.82359C4.7854 2.86304 4.72381 2.92176 4.68089 2.99347C4.63797 3.06519 4.61533 3.14721 4.61538 3.23079V6.9231C4.61533 7.00667 4.63797 7.0887 4.68089 7.16041C4.72381 7.23213 4.7854 7.29084 4.85908 7.33029C4.93277 7.36974 5.01578 7.38844 5.09926 7.38439C5.18274 7.38035 5.26355 7.35371 5.33308 7.30733L8.10231 5.46117C8.16562 5.41904 8.21753 5.36192 8.25344 5.29489C8.28935 5.22785 8.30814 5.15299 8.30814 5.07694C8.30814 5.0009 8.28935 4.92603 8.25344 4.859C8.21753 4.79196 8.16562 4.73484 8.10231 4.69271ZM5.53846 6.0606V4.09617L7.01423 5.07694L5.53846 6.0606ZM11.0769 0.923096H0.923077C0.678262 0.923096 0.443473 1.02035 0.270363 1.19346C0.0972524 1.36657 0 1.60136 0 1.84617V8.30771C0 8.55253 0.0972524 8.78731 0.270363 8.96042C0.443473 9.13353 0.678262 9.23079 0.923077 9.23079H11.0769C11.3217 9.23079 11.5565 9.13353 11.7296 8.96042C11.9027 8.78731 12 8.55253 12 8.30771V1.84617C12 1.60136 11.9027 1.36657 11.7296 1.19346C11.5565 1.02035 11.3217 0.923096 11.0769 0.923096ZM11.0769 8.30771H0.923077V1.84617H11.0769V8.30771ZM12 10.6154C12 10.7378 11.9514 10.8552 11.8648 10.9418C11.7783 11.0283 11.6609 11.0769 11.5385 11.0769H0.461538C0.339131 11.0769 0.221737 11.0283 0.135181 10.9418C0.0486262 10.8552 0 10.7378 0 10.6154C0 10.493 0.0486262 10.3756 0.135181 10.289C0.221737 10.2025 0.339131 10.1539 0.461538 10.1539H11.5385C11.6609 10.1539 11.7783 10.2025 11.8648 10.289C11.9514 10.3756 12 10.493 12 10.6154Z" fill="#AEAED4" />
                            </svg>
                            Now Playing
                        </button>
                    }
                    <button
                        className={`text-[#AEAED4] uppercase text-xs font-semibold flex items-center gap-1 py-2`}
                        style={{ borderBottom: `${toggle === "videos" ? "1px solid #7F7FFF" : "1px solid #050519"}` }}
                        onClick={() => { 
                            setToggle('videos')
                            setExpandCodeEditorComponent(true)
                         }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M8.10231 4.69271L5.33308 2.84656C5.26355 2.80017 5.18274 2.77353 5.09926 2.76949C5.01578 2.76545 4.93277 2.78415 4.85908 2.82359C4.7854 2.86304 4.72381 2.92176 4.68089 2.99347C4.63797 3.06519 4.61533 3.14721 4.61538 3.23079V6.9231C4.61533 7.00667 4.63797 7.0887 4.68089 7.16041C4.72381 7.23213 4.7854 7.29084 4.85908 7.33029C4.93277 7.36974 5.01578 7.38844 5.09926 7.38439C5.18274 7.38035 5.26355 7.35371 5.33308 7.30733L8.10231 5.46117C8.16562 5.41904 8.21753 5.36192 8.25344 5.29489C8.28935 5.22785 8.30814 5.15299 8.30814 5.07694C8.30814 5.0009 8.28935 4.92603 8.25344 4.859C8.21753 4.79196 8.16562 4.73484 8.10231 4.69271ZM5.53846 6.0606V4.09617L7.01423 5.07694L5.53846 6.0606ZM11.0769 0.923096H0.923077C0.678262 0.923096 0.443473 1.02035 0.270363 1.19346C0.0972524 1.36657 0 1.60136 0 1.84617V8.30771C0 8.55253 0.0972524 8.78731 0.270363 8.96042C0.443473 9.13353 0.678262 9.23079 0.923077 9.23079H11.0769C11.3217 9.23079 11.5565 9.13353 11.7296 8.96042C11.9027 8.78731 12 8.55253 12 8.30771V1.84617C12 1.60136 11.9027 1.36657 11.7296 1.19346C11.5565 1.02035 11.3217 0.923096 11.0769 0.923096ZM11.0769 8.30771H0.923077V1.84617H11.0769V8.30771ZM12 10.6154C12 10.7378 11.9514 10.8552 11.8648 10.9418C11.7783 11.0283 11.6609 11.0769 11.5385 11.0769H0.461538C0.339131 11.0769 0.221737 11.0283 0.135181 10.9418C0.0486262 10.8552 0 10.7378 0 10.6154C0 10.493 0.0486262 10.3756 0.135181 10.289C0.221737 10.2025 0.339131 10.1539 0.461538 10.1539H11.5385C11.6609 10.1539 11.7783 10.2025 11.8648 10.289C11.9514 10.3756 12 10.493 12 10.6154Z" fill="#AEAED4" />
                        </svg>
                        videos
                        <div className='chapter-number'>
                            {videoDetails?.length}
                        </div>
                    </button>

                    <button
                        className='text-[#AEAED4] uppercase text-xs font-semibold flex items-center gap-1  py-2 '
                        style={{ borderBottom: `${toggle === "references" ? "1px solid #7F7FFF" : "1px solid #050519"}` }}
                        value="references"
                        onClick={() => {
                            setToggle('references')
                            setExpandCodeEditorComponent(true)
                        }}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                            <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456725 3.7039C0.00259972 4.80026 -0.11622 6.00665 0.115291 7.17054C0.346802 8.33443 0.918247 9.40352 1.75736 10.2426C2.59648 11.0818 3.66557 11.6532 4.82946 11.8847C5.99335 12.1162 7.19974 11.9974 8.2961 11.5433C9.39246 11.0891 10.3295 10.3201 10.9888 9.33342C11.6481 8.34672 12 7.18669 12 6C11.9982 4.40924 11.3655 2.88414 10.2407 1.75931C9.11586 0.634472 7.59076 0.00176457 6 0ZM6 10.3889C5.50877 9.81398 5.12506 9.15532 4.86722 8.44444H7.135C6.98827 8.84643 6.80218 9.23294 6.57945 9.59833C6.40806 9.87713 6.21427 10.1415 6 10.3889ZM4.52834 7.11111C4.41833 6.37445 4.41833 5.62554 4.52834 4.88889H7.47278C7.58278 5.62554 7.58278 6.37445 7.47278 7.11111H4.52834ZM1.33334 6C1.33332 5.62554 1.37865 5.25245 1.46834 4.88889H3.18056C3.08797 5.62674 3.08797 6.37326 3.18056 7.11111H1.46834C1.37865 6.74755 1.33332 6.37446 1.33334 6ZM6 1.61111C6.49123 2.18602 6.87494 2.84468 7.13278 3.55555H4.86611C5.01284 3.15357 5.19893 2.76706 5.42167 2.40167C5.5927 2.12291 5.78612 1.85852 6 1.61111ZM8.81833 4.88889H10.5306C10.7106 5.61869 10.7106 6.3813 10.5306 7.11111H8.81945C8.91203 6.37326 8.91203 5.62674 8.81945 4.88889H8.81833ZM9.97222 3.55555H8.53945C8.33925 2.88354 8.04962 2.2415 7.67833 1.64667C8.63063 2.01635 9.43637 2.68653 9.97333 3.55555H9.97222ZM4.32167 1.64667C3.95038 2.2415 3.66075 2.88354 3.46056 3.55555H2.02667C2.56363 2.68653 3.36937 2.01635 4.32167 1.64667ZM2.02667 8.44444H3.46056C3.66075 9.11645 3.95038 9.7585 4.32167 10.3533C3.36937 9.98365 2.56363 9.31347 2.02667 8.44444ZM7.67833 10.3533C8.04962 9.7585 8.33925 9.11645 8.53945 8.44444H9.97333C9.43637 9.31347 8.63063 9.98365 7.67833 10.3533Z" fill="#AEAED4" />
                        </svg>
                        References
                        {
                            referenceMaterial?.length &&
                            <div className='chapter-number'>
                                {referenceMaterial?.length}
                            </div>
                        }

                    </button>
                </div>

                <div>
                    {
                        videoLoading ?
                            <div className='w-full h-full items-center justify-center flex'>
                                <CircularProgress />
                            </div>
                            :
                            <div>
                                {
                                    selectedVideo?.url &&
                                    <div className={`video ${((expandVideoComponent && toggle === 'videos') || (!expandVideoComponent && toggle === 'now_playing')) ? "" : "hidden"} `}>
                                        <ReactPlayer
                                            // url="https://vimeo.com/583715912/08c1e486b8"
                                            url={selectedVideo?.url}
                                            controls={true}
                                            playing={true}
                                            width="100%"
                                            height='300px'
                                            onProgress={(progress) => {
                                                if (progress.played >= 0.9 && progress.played <= 0.99999 && !hasReachedNinetyPercent) {
                                                    setHasReachedNinetyPercent(true);
                                                    handleVideoComplete();
                                                }
                                            }}
                                            onEnded={() => {
                                                // handleVideoComplete();
                                                startNextVideo()
                                            }}
                                        />
                                    </div>
                                }
                                {
                                    toggle === 'videos' &&
                                    <div className="timestamps-container">
                                        {
                                            videoDetails?.map((video, i) => (
                                                <div onClick={() => {
                                                    handleVideoChange(video)
                                                }}>
                                                    <div className={`${selectedVideo?.video_id === video?.video_id ? "selected-timestamp timestamp" : "timestamp"} flex my-3 items-center gap-2`} >
                                                        <div className="thumbnail relative w-[70px]">
                                                            <FontAwesomeIcon icon={faPlay} style={{ color: '#EDEDFF', zIndex: 2, fontSize: 11, left: '50%', top: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', }} />

                                                            <img src={thumbnail} alt="" />

                                                        </div>

                                                        <div className="content">
                                                            <div className="title flex gap-1">
                                                                <p className='text-[#F5F5FF] text-sm'>{video?.title ? video?.title : video?.video_id}</p>
                                                                {/* <p className=''></p> */}
                                                            </div>
                                                        </div>
                                                        {/* <div className='ml-auto cursor-pointer' onClick={(event) => {
                                                                event.stopPropagation();
                                                                // handleShowChapterSummary(i)
                                                            }}>
                                                                <Down_Icon />
                                                            </div> */}
                                                        {
                                                            selectedVideo?.video_id === video?.video_id ?
                                                                <button
                                                                    className='rounded-sm flex items-center gap-1 bg-[#C3C3FF] text-xs px-1 ml-auto'
                                                                    onClick={(event) => {
                                                                        event.preventDefault()
                                                                        if (video?.summary) {
                                                                            setVideoSummary(JSON.parse(video?.summary))
                                                                        }
                                                                    }}
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                        <path d="M5 4C5 3.86739 5.05268 3.74021 5.14645 3.64645C5.24021 3.55268 5.36739 3.5 5.5 3.5H10.5C10.6326 3.5 10.7598 3.55268 10.8536 3.64645C10.9473 3.74021 11 3.86739 11 4C11 4.13261 10.9473 4.25979 10.8536 4.35355C10.7598 4.44732 10.6326 4.5 10.5 4.5H5.5C5.36739 4.5 5.24021 4.44732 5.14645 4.35355C5.05268 4.25979 5 4.13261 5 4ZM5.5 6.5H10.5C10.6326 6.5 10.7598 6.44732 10.8536 6.35355C10.9473 6.25979 11 6.13261 11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5H5.5C5.36739 5.5 5.24021 5.55268 5.14645 5.64645C5.05268 5.74021 5 5.86739 5 6C5 6.13261 5.05268 6.25979 5.14645 6.35355C5.24021 6.44732 5.36739 6.5 5.5 6.5ZM14 1V8.5C14 8.89782 13.842 9.27936 13.5607 9.56066C13.2794 9.84196 12.8978 10 12.5 10H1.5C1.10336 10 0.722871 9.84291 0.441763 9.56309C0.160655 9.28327 0.00181792 8.90351 0 8.50688V2.5C0 2.36739 0.0526784 2.24021 0.146447 2.14645C0.240215 2.05268 0.367392 2 0.5 2C0.632608 2 0.759785 2.05268 0.853553 2.14645C0.947322 2.24021 1 2.36739 1 2.5V8.5C1 8.63261 1.05268 8.75979 1.14645 8.85355C1.24021 8.94732 1.36739 9 1.5 9C1.63261 9 1.75979 8.94732 1.85355 8.85355C1.94732 8.75979 2 8.63261 2 8.5V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM13 1H3V8.5C3.00025 8.67034 2.97129 8.83945 2.91438 9H12.5C12.6326 9 12.7598 8.94732 12.8536 8.85355C12.9473 8.75979 13 8.63261 13 8.5V1Z" fill="#050519" />
                                                                    </svg>
                                                                    <div>Summarise</div>
                                                                </button>
                                                                :
                                                                <div className='flex ml-auto'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                                        <path d="M5 4C5 3.86739 5.05268 3.74021 5.14645 3.64645C5.24021 3.55268 5.36739 3.5 5.5 3.5H10.5C10.6326 3.5 10.7598 3.55268 10.8536 3.64645C10.9473 3.74021 11 3.86739 11 4C11 4.13261 10.9473 4.25979 10.8536 4.35355C10.7598 4.44732 10.6326 4.5 10.5 4.5H5.5C5.36739 4.5 5.24021 4.44732 5.14645 4.35355C5.05268 4.25979 5 4.13261 5 4ZM5.5 6.5H10.5C10.6326 6.5 10.7598 6.44732 10.8536 6.35355C10.9473 6.25979 11 6.13261 11 6C11 5.86739 10.9473 5.74021 10.8536 5.64645C10.7598 5.55268 10.6326 5.5 10.5 5.5H5.5C5.36739 5.5 5.24021 5.55268 5.14645 5.64645C5.05268 5.74021 5 5.86739 5 6C5 6.13261 5.05268 6.25979 5.14645 6.35355C5.24021 6.44732 5.36739 6.5 5.5 6.5ZM14 1V8.5C14 8.89782 13.842 9.27936 13.5607 9.56066C13.2794 9.84196 12.8978 10 12.5 10H1.5C1.10336 10 0.722871 9.84291 0.441763 9.56309C0.160655 9.28327 0.00181792 8.90351 0 8.50688V2.5C0 2.36739 0.0526784 2.24021 0.146447 2.14645C0.240215 2.05268 0.367392 2 0.5 2C0.632608 2 0.759785 2.05268 0.853553 2.14645C0.947322 2.24021 1 2.36739 1 2.5V8.5C1 8.63261 1.05268 8.75979 1.14645 8.85355C1.24021 8.94732 1.36739 9 1.5 9C1.63261 9 1.75979 8.94732 1.85355 8.85355C1.94732 8.75979 2 8.63261 2 8.5V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H13C13.2652 0 13.5196 0.105357 13.7071 0.292893C13.8946 0.48043 14 0.734784 14 1ZM13 1H3V8.5C3.00025 8.67034 2.97129 8.83945 2.91438 9H12.5C12.6326 9 12.7598 8.94732 12.8536 8.85355C12.9473 8.75979 13 8.63261 13 8.5V1Z" fill="#C3C3FF" />
                                                                    </svg>
                                                                </div>
                                                        }
                                                    </div>
                                                    {/* <Collapse in={drawers[i]}>
                <div className='chapter-summary text-left' style={{ fontFamily: 'Figtree, sans-serif' }}>
                  <p style={{ fontSize: '0.7rem', margin: '0px 0px 5px 0px' }}>Clip Summary</p>
                  <p>{chapter?.description}</p>
                </div>
              </Collapse> */}
                                                </div>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                    }
                </div>

                <div style={{ display: `${toggle === 'references' ? "block" : "none"}` }}>
                    <References referenceMaterial={referenceMaterial} expandVideoComponent={expandVideoComponent} />
                </div>
            </div >
        </Collapse>
    );
}

export default VideoComponent;
