import React, { useContext } from 'react';
import '../../Common/Common.css';
import { usePostHog } from 'posthog-js/react';
import BatchEventDefault from '../../Assets/Batch_Event_Default.jpeg'

const BatchEvent = ({ setShowBatchEventModal, batchEvents }) => {

    const posthog = usePostHog()

    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const formattedHours = hours > 12 ? hours - 12 : hours;
        const ampm = hours >= 12 ? 'PM' : 'AM';
        const formattedTime = `${formattedHours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`;
        return `${formattedTime}`;
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const formattedDate = `${date.getUTCDate()}/${date.getUTCMonth() + 1}/${date.getUTCFullYear()}`;
        return `${formattedDate}`;
    };

    const generateGoogleCalendarLink = (event) => {
        const { name, start_time, end_time, instructor_name, meeting_url } = event;
        const startDateTime = new Date(start_time).toISOString().replace(/-|:|\.\d+/g, '');
        const endDateTime = new Date(end_time).toISOString().replace(/-|:|\.\d+/g, '');

        return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(name)}&dates=${startDateTime}/${endDateTime}&details=${encodeURIComponent(`Instructor: ${instructor_name}\nMeeting URL: ${meeting_url}`)}&location=${encodeURIComponent(meeting_url)}&sf=true&output=xml`;
    };

    return (
        <div className='custom-font flex flex-col'>
            <div className='grid gap-3 mb-3'>
                <h2 className='text-[#050519] font-semibold text-2xl'>Events</h2>
            </div>
            <div className='flex flex-col gap-[8px] h-[300px] overflow-scroll rounded-[5px]'>
                {batchEvents?.map((event, i) => (
                    <div className='flex bg-[#f4f4f4] p-2 rounded-[4px] items-center' key={event?.id}>
                        <div className='w-[50px] mx-1 mr-3'>
                            <img src={BatchEventDefault} alt="" />
                        </div>
                        <div>
                            <div className='font-semibold text-[#6E6E6F] text-[12px]'>
                                {formatTime(event?.start_time)} - {formatTime(event?.end_time)}, {formatDate(event?.start_time)}
                            </div>
                            <div className='font-semibold text-[#07051B]'>
                                {event?.name}
                            </div>
                            <div className='font-semibold text-[#6E6E6F] text-[13px]'>
                                {event?.instructor_name}
                            </div>
                        </div>
                        <div className='ml-auto flex flex-col gap-[8px]'>
                            <button
                                className={`w-[150px] ${i === 0 ? "bg-[#3838F2]" : "bg-[#BCBBF4] cursor-default"}  text-[#EDEDFF] p-1 rounded-[4px] font-semibold text-xs`}
                                onClick={() => {
                                    if (i === 0) {
                                        let student = localStorage.getItem("student_data")
                                        student = JSON.parse(student)
                                        posthog?.capture('click_online_class', {
                                            student_id: student?.student_id,
                                            tenant_id: event?.tenant_id,
                                            timestamp: new Date().toISOString()
                                        })
                                        window.open(event?.meeting_url, '_blank')
                                    }
                                }}
                            >
                                Join {event?.meeting_type === "GOOGLE_MEET" ? "Google Meet" : "Zoom Call"}
                            </button>
                            <a
                                className='w-[150px] bg-white text--[#565656] p-1 rounded-[4px] font-semibold text-xs text-center'
                                href={generateGoogleCalendarLink(event)}
                                target="_blank"
                            >
                                Add to Calendar
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            <div className='border-b-[1px] border-[#A7A7A7] mt-2 mb-3 opacity-[0.6] mr-1'></div>
            <button
                className='text-white bg-[#3838F1] rounded-[4px] py-2 px-3 ml-auto mt-auto mr-1'
                onClick={() => setShowBatchEventModal(false)}
            >
                Dismiss
            </button>
        </div>
    );
};

export default BatchEvent;
