import React, { useContext, useEffect, useState } from 'react'
import './Course.css'
import axios from 'axios'
import { CircularProgress, Collapse } from '@mui/material'
import { ReactComponent as Down_Icon } from '../../Assets/Down_Icon.svg'
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import { ReactComponent as Ellipse_19 } from '../../Assets/Ellipse_19.svg'
import { useNavigate, useParams } from 'react-router-dom'
import { API } from '../../Config'
import { GeneralContext } from '../../Context'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import moment from 'moment';
import "react-circular-progressbar/dist/styles.css";
import { Calendar } from '../../IconsPath'
import { PointsIcon } from '../../IconsPath'
import { Warning } from '../../IconsPath'

const Course = () => {
    const [loading, setLoading] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [drawers, setDrawers] = useState([]);
    const [continueModuleId, setContinueModuleId] = useState();
    const [continueTopicId, setContinueTopicId] = useState();
    const [actionButton, setActionButton] = useState();
    const navigate = useNavigate()
    const { course_id } = useParams();
    const { batch_id } = useParams();

    const { selectedCourse, studentInfo, courseDetails, setCourseDetails, setSelectedTopic, setSelectedModule, setStudentInfo } = useContext(GeneralContext);

    const formatDate = (dateString) => {
        return moment(dateString).format('D MMMM');
    };

    function isTopicStartDateValid(topicStartDateStr) {
        const topicStartDate = new Date(topicStartDateStr);

        const currentDate = new Date();

        return topicStartDate <= currentDate;
    }

    function extractHours(timeString) {
        if (!timeString) {
            return null
        }
        const [hours] = timeString.split(':');
        return parseInt(hours, 10);
    }

    const handleShowChapterSummary = (chapterId) => {
        const newOpenDrawers = [];
        const currentDrawer = drawers[chapterId]
        newOpenDrawers[chapterId] = !currentDrawer;
        setDrawers(newOpenDrawers);
    }

    function formatTopicDate(dateString) {
        if (!dateString) {
            return null
        }
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short' };
        let formattedDate = date.toLocaleDateString('en-GB', options)
        formattedDate = formattedDate.replace(/([a-zA-Z]{3})/, (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase());

        return formattedDate;
    }

    const getCourseDetails = async () => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)

            setLoading(true)
            if (student) {
                let url = `${API}/course/student-course-details?course_id=${course_id}&student_id=${student?.student_id}&batch_id=${batch_id}`
                const { data } = await axios.get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                setCourseDetails(data?.data)
                setLoading(false)
                return data?.data
            }
            else {
                navigate('/')
            }
            setLoading(false)
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    navigate('/login')
                }
            }
            setLoading(false)
            console.log(e.message)
        }
    }

    const handleStartCourse = async (module, topic) => {
        try {
            let token = localStorage.getItem("token")
            let student = localStorage.getItem("student_data")

            if (!token || !student) {
                navigate('/login')
            }
            token = JSON.parse(token)
            student = JSON.parse(student)
            setSubmitLoading(true)
            const result = await axios.post(`${API}/course/start-course`, {
                course_id: selectedCourse.course_id,
                student_id: studentInfo.student_id,
                batch_id: selectedCourse.batch_id
            },
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Add your token here
                    }
                }
            )
            let url = `${API}/course/student-course-details?course_id=${selectedCourse?.course_id}&student_id=${studentInfo?.student_id}&batch_id=${batch_id}`
            const { data } = await axios.get(url,
                {
                    headers: {
                        Authorization: `Bearer ${token}` // Add your token here
                    }
                })
            setCourseDetails(data?.data)
            data?.data?.children?.forEach((m) => {
                if (m?.node_id === module?.node_id) {
                    m?.children?.forEach((t) => {
                        if (t?.node_id === topic?.node_id) {
                            setSelectedTopic(t)
                        }
                    })
                }
            })
            setSubmitLoading(false)
            navigate('/learn')
        }
        catch (e) {
            if (e.response) {
                if (e.response?.data?.message === "Unauthorized") {
                    setStudentInfo(null)
                    navigate('/login')
                }
            }
            setSubmitLoading(false)
            console.log(e.message)
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            const data = await getCourseDetails();
            let flag1 = 0
            let flag2 = 0
            if (!isTopicStartDateValid(data?.start_date)) {
                setDrawers([true]);
            }
            else {
                setDrawers([])
                let temp = []

                data?.children?.some((module, i) => {
                    if (module?.progress === 100) {
                        temp.push(null)
                        return false
                    }
                    else {
                        temp.push(true)
                        setContinueModuleId(i)
                        flag1 = 1
                        module?.children?.some((topic, j) => {
                            if (topic?.progress_percentage === 0) {
                                setContinueTopicId(j)
                                flag2 = 1
                                setActionButton("Start")
                                return true
                            }
                            else if (topic?.progress_percentage !== 100) {
                                setContinueTopicId(j)
                                flag2 = 1
                                setActionButton("Resume")
                                return true
                            }
                            else {
                                return false
                            }

                        })
                        return true
                    }
                })

                // if (!flag2 && flag1) {
                //     setContinueTopicId(0)
                //     setActionButton("Start")
                // }
                // else {
                //     setActionButton("Continue")
                // }
                setDrawers(temp)
            }
        };

        fetchData();
    }, []);

    return (
        <div className='h-full flex items-center justify-center'>
            {
                loading ?
                    <CircularProgress />
                    :
                    <div
                        className='course-container'
                        style={{
                            // background: `linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.80) 100%), url(${TestBackground}) lightgray 50% / cover no-repeat`
                        }}
                    >
                        <div className="">
                            <div className="course-heading">
                                {courseDetails?.name}
                            </div>
                            <div className='course-description'>


                                <div className='flex gap-2 items-center'>
                                    {
                                        isTopicStartDateValid(courseDetails?.start_date) &&
                                        ((courseDetails?.progress) === 100 ?
                                            <Task_Complete_Icon />
                                            :
                                            <div className='circular-progress-container w-[16px]'>
                                                <CircularProgressbar
                                                    value={courseDetails?.progress}
                                                    strokeWidth={50}
                                                    styles={buildStyles({
                                                        strokeLinecap: "butt"
                                                    })}
                                                />
                                            </div>)
                                    }
                                    {
                                        isTopicStartDateValid(courseDetails?.start_date) &&
                                        <div className='text-[#42B25B]'>
                                            {Math.round(courseDetails?.progress)}% Complete
                                            <span className=''> • {courseDetails?.points_earned} pts</span>
                                        </div>
                                    }

                                    <div className='text-[#6E6E6F] flex gap-1 items-center text-xm ml-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                                            <path d={Calendar} fill="#6E6E6F" />
                                        </svg>
                                        {formatDate(courseDetails?.start_date)} - {formatDate(courseDetails?.end_date)}
                                    </div>
                                    <span className='flex items-center ml-2 text-xm gap-1'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 10 12" fill="none">
                                            <path d={PointsIcon} fill="#6E6E6F" />
                                        </svg>
                                        {courseDetails?.points} points
                                    </span>
                                    {
                                        !isTopicStartDateValid(courseDetails?.start_date) &&
                                        // courseDetails?.status === "PENDING" &&
                                        <div className='flex items-center gap-[3px] ml-2'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 15 15" fill="none">
                                                <path d={Warning} fill="#D48134" />
                                            </svg>
                                            <span className='text-[#D48134]'>You can access this course after {formatDate(courseDetails?.start_date)}</span>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="course-details">
                            {/* <div className="header">
                    <span>Course Plan</span>
                </div> */}
                            <div className="line"></div>
                            <div className='course-content'>
                                {
                                    courseDetails?.children?.map((module, i) => (
                                        <div className={`${drawers[i] ? "bg-[#F4F4F4]" : ""} rounded-lg`}>
                                            <div className={`module cursor-pointer`} onClick={(event) => {
                                                event.stopPropagation();
                                                handleShowChapterSummary(i)
                                            }}>
                                                <div>
                                                    <div className={`rounded-lg flex items-center gap-2`}>
                                                        {
                                                            module?.progress === 100 ?
                                                                <Task_Complete_Icon />
                                                                :
                                                                <div className='circular-progress-container w-[16px]'>
                                                                    <CircularProgressbar
                                                                        value={module?.progress}
                                                                        strokeWidth={50}
                                                                        styles={buildStyles({
                                                                            strokeLinecap: "butt"
                                                                        })}
                                                                    />
                                                                </div>
                                                        }


                                                        <span className={`${(drawers[i] || module?.progress === 100) ? "text-black" : "text-[#6E6E6F]"}`}>{module?.node_name}</span>
                                                    </div>
                                                    {/* <div className='flex gap-2 items-center'>
                                                        <div className='text-[#A7A7A7] text-sm'>12-15 Jun</div>
                                                        <div className='text-[#A7A7A7] text-sm'>95 points</div>
                                                        <div className='text-[#A7A7A7] text-sm'>6 hrs, 1 Test</div>
                                                    </div> */}
                                                    <div className={`${drawers[i] ? "" : "hidden"} text-[#6E6E6F] text-xs font-semibold ml-6 mt-2`}>{module?.points} points</div>
                                                </div>
                                                <div className='expand-icon-container'>
                                                    <Down_Icon />
                                                </div>
                                            </div>
                                            {
                                                drawers[i] &&
                                                <div className="line"></div>
                                            }
                                            <Collapse in={drawers[i]}>
                                                <div className='chapter-summary text-left' style={{ fontFamily: 'Figtree, sans-serif' }}>
                                                    {
                                                        module?.children?.map((topic, j) => (
                                                            <div className="topic relative group ">
                                                                <div className='flex items-center w-[70%] gap-2'>
                                                                    {
                                                                        Number(topic?.progress_percentage) === 100 ?
                                                                            <Task_Complete_Icon />
                                                                            :
                                                                            <div className='circular-progress-container w-[16px]'>
                                                                                <CircularProgressbar
                                                                                    value={Number(topic?.progress_percentage)}
                                                                                    strokeWidth={50}
                                                                                    styles={buildStyles({
                                                                                        strokeLinecap: "butt"
                                                                                    })}
                                                                                />
                                                                            </div>
                                                                    }

                                                                    {/* <span className={`${(topic?.status === "IN_PROGRESS" || ((Number(topic?.points_earned) / Number(topic?.points)) * 100) === 100) ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{formatTopicDate(topic?.topic_start_date)}</span>
                                                                    <span className={`${(topic?.status === "IN_PROGRESS" || ((Number(topic?.points_earned) / Number(topic?.points)) * 100) === 100) ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{topic?.node_name}</span> */}
                                                                    <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold`}>{formatTopicDate(topic?.topic_start_date)}</span>
                                                                    <span className={`${topic?.is_accessable ? "text-black" : "text-[#6E6E6F]"} font-semibold w-[80%]`}>{topic?.node_name}</span>
                                                                </div>
                                                                <div className='flex items-center ml-auto'>
                                                                    {
                                                                        ((i === continueModuleId && j === continueTopicId) && topic?.is_accessable && topic?.progress_percentage !== null) ?
                                                                            <div className="button-container ml-auto">
                                                                                <button
                                                                                    className='text-[12px] text-white rounded-md px-2 w-fit flex items-center justify-center bg-[#3838F1] font-medium'
                                                                                    onClick={() => {
                                                                                        setSelectedTopic(topic)
                                                                                        setSelectedModule(module)
                                                                                        navigate('/learn')
                                                                                    }}
                                                                                >
                                                                                    {actionButton}
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            topic?.progress_percentage !== null &&
                                                                            <div className="button-container ml-auto opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                                {
                                                                                    <button
                                                                                        className='text-[12px] text-white rounded-md px-2 w-fit flex items-center justify-center bg-[#3838F1] font-medium'
                                                                                        onClick={() => {
                                                                                            setSelectedTopic(topic)
                                                                                            setSelectedModule(module)
                                                                                            navigate('/learn')
                                                                                        }}
                                                                                    >
                                                                                        {(Number(topic?.progress_percentage) === 100) ?
                                                                                            "See Content"
                                                                                            :
                                                                                            (Number(topic?.progress_percentage) === 0 && topic?.is_accessable) ?
                                                                                                "Start"
                                                                                                :
                                                                                                topic?.is_accessable && "Resume"
                                                                                        }
                                                                                    </button>
                                                                                }
                                                                            </div>
                                                                    }
                                                                    <div className='text-xs ml-2 flex gap-2 w-[80px]'>
                                                                        <div className='w-[30px] text-right'>
                                                                            {
                                                                                (topic?.duration && extractHours(topic?.duration)) ?
                                                                                    <span>{extractHours(topic?.duration) + "hrs"}</span>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </div>
                                                                        <div className='w-[50px] text-right'>
                                                                            {
                                                                                topic?.points ?
                                                                                    <span>{topic?.points} Pts</span>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Collapse>
                                        </div>

                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

        </div>
    )
}

export default Course