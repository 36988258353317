import React, { createContext, useState } from 'react';

// Create a new context
export const GeneralContext = createContext();

// Provider component
export const CourseProvider = ({ children }) => {

    const [currentCourse, setCurrentCourse] = useState()
    const [selectedCourse, setSelectedCourse] = useState()
    const [selectedModule, setSelectedModule] = useState()
    const [selectedTopic, setSelectedTopic] = useState()
    const [courseDetails, setCourseDetails] = useState()
    const [studentInfo, setStudentInfo] = useState()
    const [videoDetails, setVideoDetails] = useState()
    const [taskDetails, setTaskDetails] = useState([])
    const [openSnackbar, setOpenSnackbar] = useState(false)
    const [snackbar, setSnackbarMessage] = useState("")
    const [showBatchEventModal, setShowBatchEventModal] = useState(false);

    return (
        <GeneralContext.Provider value={{ currentCourse, setCurrentCourse, studentInfo, setStudentInfo, selectedCourse, setSelectedCourse, selectedModule, setSelectedModule, courseDetails, setCourseDetails, selectedTopic, setSelectedTopic, videoDetails, setVideoDetails, taskDetails, setTaskDetails, openSnackbar, setOpenSnackbar, snackbar, setSnackbarMessage, showBatchEventModal, setShowBatchEventModal }}>
            {children}
        </GeneralContext.Provider>
    );
};
