import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CourseProvider } from './Context'
import { PostHogProvider } from 'posthog-js/react'
import posthog from "posthog-js"
import zipy from 'zipyai'
zipy.init('ac55bd69')

if (typeof window !== 'undefined') {
  posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY,
    {
      person_profiles: 'identified_only',
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      disable_session_recording: true,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false
    }
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CourseProvider>
    {/* <React.StrictMode> */}
    <PostHogProvider client={posthog}>
      <App />
      {/* </React.StrictMode> */}
    </PostHogProvider>
  </CourseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
