import React, { useContext, useEffect, useRef, useState } from 'react';
import './CodeEditor.css';
import { CircularProgress, Collapse } from '@mui/material';
import Editor from "@monaco-editor/react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp, faPlay } from '@fortawesome/free-solid-svg-icons';
import { AI_API } from '../../Config';
import '../../Common/Common.css'
import { usePostHog } from 'posthog-js/react';
import { GeneralContext } from '../../Context';
import { Option, Select } from '@mui/joy';

function CodeEditor({ language, setLanguage, expantConsole, setExpantConsole, runCodeLoading, setRunCodeLoading, selectedTask, setExpandCodeEditorComponent, expandCodeEditorComponent, code, setCode, outputDetails, setOutputDetails }) {

    const editorRef = useRef(null);
    const posthog = usePostHog()
    let student = localStorage.getItem("student_data")
    student = JSON.parse(student)

    const { courseDetails } = useContext(GeneralContext);

    const handleCompile = async () => {
        try {
            // const body = {
            //   input_: JSON.stringify(assignmentData?.unitTests[0]?.input),
            //   output: JSON.stringify(assignmentData?.unitTests[0]?.output),
            //   lang: youtubeVideoDetails?.language || 'python',
            //   code_file: JSON.stringify(code)
            // }
            const transformed = code.split('\n').map(line => line.replace(/^\s+/, match => match.replace(/ {4}/g, '    '))).join('\n');
            let body = {
                language: language || "java",
                stdin: language === 'mysql' ? "" : "string",
                files: transformed
            }

            // const body = {
            //   "input_": "[1,2,3]",
            //   "output": "OP",
            //   "lang": "Python",
            //   "code_file": "print(\"OP\")\nprint(\"\")"
            // }
            setRunCodeLoading(true)

            const { data } = await axios.post(`${AI_API}/run_code`, body);
            // setOutputDetails(data?.data)
            if (data?.exception) {
                posthog?.capture('error_in_run_api', {
                    student_id: student?.student_id,
                    task_id: selectedTask?.task_id,
                    course_id: courseDetails?.course_id,
                    batch_id: courseDetails?.batch_id,
                    tenant_id: courseDetails?.tenant_id,
                    error: data?.stderr,
                    code: transformed,
                    timestamp: new Date().toISOString()
                })
            }
            setOutputDetails(data)
            setRunCodeLoading(false)
        }
        catch (e) {
            setRunCodeLoading(false)
            setOutputDetails(null)
            console.log("Error while calling Code Run API:", e.message)
        }
    };

    const onMount = (editor, monaco) => {
        editorRef.current = editor;
        editor.focus();

        editor.onKeyDown((event) => {
            const { keyCode, ctrlKey, metaKey } = event;
            if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
                event.preventDefault();
            }
        });
    };

    return (
        <div className='editor-container'>
            <div className="editor-header px-[10px]">
                {/* <div className="language uppercase">{selectedTask?.language || courseDetails?.language}</div> */}
                <Select
                    // disabled={ selectedTask ? true : false}
                    placeholder=""
                    name="foo"
                    required
                    sx={{ padding: '1px 5px', minWidth: 150, backgroundColor: '#1d1d2e !important', borderColor: '#1d1d2e !important', color: '#E1E7E8', textTransform: 'uppercase !important' }}
                    className='language'
                    onChange={(e, newValue) => {
                        setLanguage(newValue)
                    }}
                    value={language || "java"}
                >
                    <Option className='language language-dropdown-option' value="java">JAVA</Option>
                    <Option className='language language-dropdown-option' value="javascript">JAVASCRIPT</Option>
                    <Option className='language language-dropdown-option' value="mysql">MYSQL</Option>
                    <Option className='language language-dropdown-option' value="python">PYTHON</Option>
                    <Option className='language language-dropdown-option' value="bash">BASH</Option>
                    <Option className='language language-dropdown-option' value="kotlin">KOTLIN</Option>
                    <Option className='language language-dropdown-option' value="typescript">TYPESCRIPT</Option>
                    <Option className='language language-dropdown-option' value="others">OTHERS</Option>
                </Select>
                <div className="button-container">
                    <div className={`flex items-center status uppercase text-w text-xs font-medium ${outputDetails?.exception === true ? 'text-green-600' : 'text-red-600'} ml-auto`}>
                    </div>
                    <button
                        className='editor-button'
                        onClick={() => {
                            setCode("")
                            setOutputDetails(null)
                        }}
                    >
                        CLEAR
                    </button>
                    {
                        !runCodeLoading ?
                            <button
                                className='editor-button'
                                onClick={() => {
                                    posthog?.capture('click_run_task', {
                                        student_id: student?.student_id,
                                        task_id: selectedTask?.task_id,
                                        course_id: courseDetails?.course_id,
                                        batch_id: courseDetails?.batch_id,
                                        tenant_id: courseDetails?.tenant_id,
                                        timestamp: new Date().toISOString()
                                    })
                                    handleCompile()
                                }}
                            >
                                <FontAwesomeIcon icon={faPlay} fontSize={10} />
                                RUN
                            </button>
                            :
                            <button className='editor-button'>
                                <div className='flex justify-center items-center w-[34px]'>
                                    <CircularProgress style={{ height: 15, width: 15 }} />
                                </div>
                            </button>
                    }
                    <button className='editor-button'
                        onClick={() => {
                            setExpandCodeEditorComponent(!expandCodeEditorComponent)
                        }}
                    >
                        {
                            expandCodeEditorComponent ?
                                <FontAwesomeIcon icon={faAngleUp} />
                                :
                                <FontAwesomeIcon icon={faAngleDown} />
                        }
                    </button>
                </div>
            </div>
            <div className="editor px-[10px]">
                <Editor
                    className='rounded code-editor'
                    language={language || "java"}
                    wrapperClassName="font-mono"
                    value={code}
                    theme="vs-dark"
                    defaultValue=""
                    onChange={(value) => {
                        setCode(value);
                    }}
                    options={{
                        scrollbar: {
                            alwaysConsumeMouseWheel: false,
                        },
                        scrollBeyondLastLine: false,
                        contextmenu: false,
                    }}
                    onMount={onMount}
                />
            </div>

            <div className={`expandable-content ${expantConsole ? 'expanded' : ''} editor-console w-full flex flex-col box-border bg-[#2F2F37] py-2 px-3`}>
                <div className="console-header flex items-center gap-3 cursor-pointer" onClick={() => { setExpantConsole(!expantConsole) }}>
                    <div className='text-[#E1E7E8] text-[13px] font-normal code-font'>CONSOLE</div>
                    {
                        (outputDetails?.exception || outputDetails?.stderr) ?
                            <div className='text-[13px] text-[#C64537] code-font'>
                                Error!
                            </div>
                            :
                            // outputDetails?.stdout ?
                            <div className='text-[13px] text-[#37C66B] code-font'>
                                No Errors
                            </div>
                        // :
                        // ""
                    }
                    <button className='ml-auto text-[#E1E7E8]'>
                        {
                            !expantConsole ?
                                <FontAwesomeIcon icon={faAngleUp} />
                                :
                                <FontAwesomeIcon icon={faAngleDown} />
                        }
                    </button>
                </div>
                {/* <div className='flex-1 mt-2'> */}
                {
                    outputDetails?.stderr ?
                        <pre className='text-xs text-[#C64537] code-font flex-1 overflow-scroll mt-2'>
                            <div className='code-font tracking-wider leading-normal' dangerouslySetInnerHTML={{ __html: outputDetails?.stderr?.replace(/ /g, '&nbsp;') }} />
                        </pre>
                        :
                        outputDetails?.exception ?
                            <pre className='text-xs text-[#C64537] code-font flex-1 overflow-scroll mt-2'>
                                <div className='code-font tracking-wider leading-normal' dangerouslySetInnerHTML={{ __html: outputDetails?.exception?.replace(/ /g, '&nbsp;') }} />
                            </pre>
                            :
                            outputDetails?.stdout ?
                                <pre className='text-xs text-[#82828C] code-font flex-1 overflow-scroll mt-2'>
                                    <div className='code-font tracking-wider leading-normal' dangerouslySetInnerHTML={{ __html: outputDetails?.stdout?.replace(/ /g, '&nbsp;') }} />
                                </pre>
                                :
                                ""
                }

                {/* </div> */}
            </div>
        </div>
    );
}

export default CodeEditor;