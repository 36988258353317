import { useContext, useEffect } from 'react';
import './Mcq.css';
import React, { useState } from 'react';
import TaskCode from '../TaskCode';

function Mcq({ mcqQuestion, handleAnswer ,taskAnswer}) {

    const [answered, setAnswered] = useState(false)
    const [clickedOption, setClickedOption] = useState()

    

    const handleOptionClicked = (isCorrect, option) => {
        setAnswered(true)
        handleAnswer(isCorrect, option)
    }

    useEffect(() => {
        if (taskAnswer && mcqQuestion?.mcq_options) {
            const selectedOptionIndex = mcqQuestion.mcq_options.findIndex(option => option.text === taskAnswer?.option?.text);
            
            if (selectedOptionIndex !== -1) {
                setClickedOption(selectedOptionIndex); 
                setAnswered(true);
            }
        }
    }, [taskAnswer, mcqQuestion]);

    
    // const toOrdinal = (number) => {
    //     const suffixes = ['th', 'st', 'nd', 'rd'];
    //     const v = number % 100;
    //     return number + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    // };

    return (

        <div className="mcq">
            <div className="mcq-container" >
                <div className="question-container">
                    {mcqQuestion?.question}
                </div>
                {
                    mcqQuestion?.image_url ?
                        <img src={mcqQuestion?.image_url} alt="" style={{ width: '100%', borderRadius: 6 }} />
                        :
                        ""
                }
                {
                    mcqQuestion?.code_snippet ?
                        <TaskCode code={mcqQuestion?.code_snippet} language={mcqQuestion?.language} />
                        :
                        ""
                }
                <div className="option-container">
                    {
                        mcqQuestion?.mcq_options?.map((option, i) => (
                            <div className={`option ${answered ? (clickedOption === i && !option?.correct) ? "incorrect-option" : "" : "cursor-pointer"} ${(answered && option?.correct) ? "correct-option" : ""}`}
                                onClick={() => {
                                    if (!answered) {
                                        setClickedOption(i)
                                        handleOptionClicked(option?.correct, option)
                                    }
                                }}
                            >
                                {
                                    (i === 0 ? "A "
                                        : i === 1 ? "B "
                                            : i === 2 ? "C "
                                                : "D ") 
                                }
                                <span className='ml-1'>{option?.text}</span>
                            </div>
                        ))
                    }
                </div>

            </div>

        </div>

    );
}

export default Mcq;
