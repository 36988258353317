import React, { useContext, useEffect, useState } from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import "react-circular-progressbar/dist/styles.css";
import { ReactComponent as Task_Complete_Icon } from '../../Assets/Task_Complete_Icon.svg'
import { ReactComponent as Task_Complete_Icon_Black_And_White } from '../../Assets/Task_Complete_Icon_Black_And_White.svg'
import { GeneralContext } from '../../Context';

function Topic({ topic, completedVideos, taskDetails, topicCompleted, batchEvents }) {

    const { videoDetails } = useContext(GeneralContext);
    const [completedVideosCount, setCompletedVideosCount] = useState()
    const [completedTaskCount, setCompletedTaskCount] = useState()
    const [taskPoints, setTaskPoints] = useState(0)
    const [videoPoints, setVideoPoints] = useState(0)
    const [mcqTasks, setMcqTasks] = useState(0)
    const [mcqTaskProgress, setMcqTaskProgress] = useState(0)
    const [mcqTaskPoints, setMcqTaskPoints] = useState(0)
    let p = 0

    const calculateVideoProgress = () => {
        let temp = 0
        let points = 0
        topic?.videos?.forEach((video) => {
            if (video?.status === 'COMPLETED') {
                ++temp
            }
        })
        setCompletedVideosCount(temp)
        videoDetails?.map((video) => {
            points = points + video?.points
        })
        setVideoPoints(points)
    }

    const calculateMcqTaskProgress = () => {
        let total = 0
        let completed = 0
        let points = 0
        taskDetails?.forEach((task) => {
            if (task?.task_type === 'MCQ') {
                total = total + 1
                points = points + task?.points
                if (task?.status === 'COMPLETED') {
                    completed = completed + 1
                }
            }
        })
        setMcqTasks(total)
        setMcqTaskProgress((completed / total) * 100)
        setMcqTaskPoints(points)
    }

    const calculateTaskProgress = () => {
        let temp = 0
        let points = 0
        taskDetails?.forEach((task) => {
            if (task?.status === 'COMPLETED') {
                ++temp
            }
            points = points + task?.points

        })
        setTaskPoints(points)
        setCompletedTaskCount(temp)
    }

    const checkIfCompleted = (targetTime) => {
        const now = new Date();
        const target = new Date(targetTime);
        const difference = target - now;

        if (difference > 0) {
            return true
        } else {
            return false
        }
    };

    const calculateTimeDifference = (sourseTime, targetTime) => {
        const now = new Date(sourseTime);
        const target = new Date(targetTime);
        const difference = target - now;

        if (difference > 0) {
            const seconds = Math.floor(difference / 1000);
            const minutes = Math.floor(seconds / 60);
            const hours = Math.floor(minutes / 60);
            const days = Math.floor(hours / 24);

            if (days > 0) {
                return `${days}days`;
            } else if (hours > 0) {
                return `${hours}hrs`;
            } else if (minutes > 0) {
                return `${minutes}min`;
            } else {
                return `${seconds}sec`;
            }
        } else {
            return '';
        }
    };

    const increament = () => {
        p++
    }

    useEffect(() => {
        calculateVideoProgress()
        calculateTaskProgress()
        calculateMcqTaskProgress()
    }, [])

    return (
        <div className='bg-[#F5F5FF] p-2 px-3 w-full rounded-md '>
            {
                topicCompleted ?
                    <div className='text-[#050519] text-[14.5px] opacity-[0.9] font-semibold mb-2'>You have successfully finished this topic and have no videos or tasks left to attend to. However, you can see the videos, resources and tasks for reference.</div>
                    :
                    <>
                        <div className='text-black font-semibold py-2 text-lg'> Today: {topic?.node_name} </div>
                        <div className='text-[#050519] text-[14.5px] opacity-[0.9] font-semibold mb-2'>Today's Topics are all about fundamentals of {topic?.node_name}. Here are a set of activities lined up for you.</div>
                    </>
            }
            {
                topic?.videos?.length ?
                    <div className='flex items-center p-2'>
                        {
                            (((Number(completedVideosCount) / Number(topic?.videos?.length)) * 100) === 100 || completedVideos) ?
                                <Task_Complete_Icon />
                                :
                                <div className='circular-progress-container w-[16px]'>
                                    <CircularProgressbar
                                        value={((Number(completedVideosCount) / Number(topic?.videos?.length)) * 100)}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: "butt"
                                        })}
                                    />
                                </div>
                        }
                        <div className='text-black font-semibold text-sm ml-2'>
                            {topic?.videos?.length} Topic Videos
                        </div>
                        <div className='ml-auto flex gap-2'>
                            {/* <span className='text-[#6E6E6F] text-xs'>1.5 hrs</span> */}
                            <span className='text-[#6E6E6F] text-xs'>{videoPoints} pts</span>
                        </div>
                    </div>
                    :
                    ""
            }

            {
                mcqTasks > 0 ?
                    <div className='flex items-center p-2'>
                        {
                            mcqTaskProgress === 100 ?
                                <Task_Complete_Icon />
                                :
                                <div className='circular-progress-container w-[16px]'>
                                    <CircularProgressbar
                                        value={mcqTaskProgress}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: "butt"
                                        })}
                                    />
                                </div>
                        }
                        <div className='text-black font-semibold text-sm ml-2'>
                            {mcqTasks} Multiple Choice Questions
                        </div>
                        <div className='ml-auto flex gap-2'>
                            {/* <span className='text-[#6E6E6F] text-xs'>1.5 hrs</span> */}
                            <span className='text-[#6E6E6F] text-xs'>{mcqTaskPoints} pts</span>
                        </div>
                    </div>
                    :
                    ""
            }

            {
                taskDetails?.map((task, i) => (
                    task?.task_type !== "MCQ" ?
                        <div className='flex items-center p-2'>
                            {increament()}
                            {
                                task?.status === "COMPLETED" ?
                                    <Task_Complete_Icon />
                                    :
                                    <div className='circular-progress-container w-[16px]'>
                                        <CircularProgressbar
                                            value={0}
                                            strokeWidth={50}
                                            styles={buildStyles({
                                                strokeLinecap: "butt"
                                            })}
                                        />
                                    </div>
                            }
                            <div className='text-black font-semibold text-sm ml-2'>
                                {task?.task_type === 'DOC' ? 'Reading' : "Coding"} Task {p}
                            </div>
                            <div className='ml-auto flex gap-2'>
                                {/* <span className='text-[#6E6E6F] text-xs'>1.5 hrs</span> */}
                                <span className='text-[#6E6E6F] text-xs'>{task?.points} pts</span>
                            </div>
                        </div>
                        :
                        ""
                ))
            }
            {
                batchEvents?.map((event) => (
                    <div className='flex items-center p-2'>
                        <div className='circular-progress-container w-[16px]'>
                            {
                                checkIfCompleted(event?.start_time) ?
                                    <CircularProgressbar
                                        value={0}
                                        strokeWidth={50}
                                        styles={buildStyles({
                                            strokeLinecap: "butt"
                                        })}
                                    />
                                    :
                                    <Task_Complete_Icon_Black_And_White />
                            }
                        </div>
                        <div className='text-[#050519] opacity-[0.7] font-semibold text-sm ml-2 capitalize'>
                            {event?.name + ": " + event?.instructor_name}
                        </div>
                        <div className='ml-auto flex gap-2'>
                            {
                                calculateTimeDifference(event?.start_time, event?.end_time) &&
                                <span className='text-[#6E6E6F] text-xs'>{calculateTimeDifference(event?.start_time, event?.end_time)}</span>
                            }
                            {/* <span className='text-[#6E6E6F] text-xs'>{task?.points} pts</span> */}
                        </div>
                    </div>
                ))
            }

        </div>
    )
}

export default Topic