import React, { useContext, useEffect, useState, useRef } from 'react';
import { ReactComponent as CompanyLogo2 } from '../../Assets/Rooman_Logo.svg'
import { Button, Input, Modal, ModalClose, Sheet } from '@mui/joy';
import './LoginWithEmail.css'
import '../../Common/Common.css'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { API } from '../../Config';
import { GeneralContext } from '../../Context';
import UAParser from 'ua-parser-js';
import { CircularProgress } from '@mui/material';
import { usePostHog } from 'posthog-js/react'
import zipy from "zipyai";

function LoginWithEmail() {
    const posthog = usePostHog()

    const navigate = useNavigate()
    const [OTP, setOTP] = useState();
    const [email, setEmail] = useState();
    const [showForgotComp, setShowForgotComp] = useState(false);
    const [forgotMsg, setForgotMsg] = useState("");
    const [password, setPassword] = useState();
    const [sendOTP, setSendOTP] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [passwordErrorMessage, setPasswordErrorMessage] = useState();
    const [showPasswordErrorMessage, setShowPasswordErrorMessage] = useState(false);

    const [studentId, setStudentId] = useState();
    const [loading, setLoading] = useState(false);
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
    const [validateLoading, setValidateLoading] = useState(false);
    const [alreadyLoggedInModal, setAlreadyLoggedInModal] = useState(false);
    const { setStudentInfo } = useContext(GeneralContext);

    // Email Regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const emailRef = useRef(null);
    const otpRef = useRef(null);

    const checkStudentLogin = async () => {
        try {
            setLoading(true)
            const { data } = await axios.post(`${API}/student/check-student-login`, {
                email: email
            })
            setStudentId(data?.data?.student_id)
            // setSendOTP(true)
            loginStudent()

            // setLoading(false)
            setShowErrorMessage(false)
        }
        catch (e) {
            setLoading(false)
            if (e.response) {
                handleErrorFromSendOtpApi(e.response.data?.message)
            }
            console.log(e.message)
        }
    }

    const loginStudent = async () => {
        try {
            setLoading(true)
            const deviceInfo = getDeviceInfo()
            const { data } = await axios.post(`${API}/student/authenticate`, {
                email: email,
                password: password,
                device_info: deviceInfo
            })
            setLoading(false)
            setStudentInfo(data?.data?.student_data)

            if (data?.data?.student_data) {
                posthog?.identify(data?.data?.student_data.student_id, {
                    email: data?.data?.student_data.email,
                })
                zipy.identify(data?.data?.student_data?.email, {
                    id: data?.data?.student_data?.student_id,
                    name: data?.data?.student_data?.name,
                    email: data?.data?.student_data?.email,
                    mobile_number: data?.data?.student_data?.mobile_number,
                });
            }

            posthog?.capture('click_student_login', {
                student_id: data?.data?.student_data?.student_id,
                timestamp: new Date().toISOString()
            })

            localStorage.setItem("student_data", JSON.stringify(data?.data?.student_data))
            localStorage.setItem("token", JSON.stringify(data?.data?.token))
            navigate('/')
        }
        catch (e) {
            if (e.response) {
                setPasswordErrorMessage(e.response.data?.message)
                setShowPasswordErrorMessage(true)
                handleErrorFromValidateApi(e.response.data?.message)
            }
            setLoading(false)
        }
    }

    const forgotPassword = async () => {
        try {
            setForgotPasswordLoading(true)
            const deviceInfo = getDeviceInfo()
            const { data } = await axios.post(`${API}/student/forgot-password`, {
                email: email
            })
            setForgotPasswordLoading(false)
            setForgotMsg(data?.message);
            // setStudentInfo(data?.data?.student_data)
        }
        catch (e) {
            if (e.response) {
                setErrorMessage(e?.response?.data?.message)
                setShowErrorMessage(true)
            }
            setForgotPasswordLoading(false)
        }
    }

    // const sendOtpToEmail = async () => {
    //     try {
    //         setLoading(true)
    //         const { data } = await axios.post(`${API}/student/otp/generate`, {
    //             email: email
    //         })
    //         setStudentId(data?.data?.student_id)
    //         setSendOTP(true)
    //         setLoading(false)
    //         setShowErrorMessage(false)
    //     }
    //     catch (e) {
    //         setLoading(false)
    //         if (e.response) {
    //             handleErrorFromSendOtpApi(e.response.data?.message)
    //         }
    //         console.log(e.message)
    //     }
    // }

    const getDeviceInfo = () => {
        const parser = new UAParser();
        const result = parser.getResult();

        return {
            browser: `${result.browser.name} ${result.browser.version}`,
            os: `${result.os.name} ${result.os.version}`
        }
    };

    const handleErrorFromSendOtpApi = (errorMessage) => {
        if (errorMessage === "Please log out from another system") {
            setAlreadyLoggedInModal(true)
        }
        else {
            setErrorMessage(errorMessage)
            setShowErrorMessage(true)
        }
    }

    const logoutStudent = async () => {
        try {
            setAlreadyLoggedInModal(false)
            let student = localStorage.getItem("student_data")
            student = JSON.parse(student)
            const deviceInfo = getDeviceInfo()

            const { data } = await axios.post(`${API}/student/logout`, {
                email: email,
                device_info: deviceInfo
            })
            setStudentInfo(null)
            // sendOtpToEmail()
        }
        catch (e) {
            console.log("Error", e.message)
        }
    }

    const handleErrorFromValidateApi = (errorMessage) => {
        console.log(errorMessage)
    }

    // const validateOtp = async () => {
    //     try {
    //         setValidateLoading(true)
    //         const deviceInfo = getDeviceInfo()
    //         const { data } = await axios.post(`${API}/student/otp/authenticate`, {
    //             student_id: studentId,
    //             otp: Number(OTP),
    //             device_info: deviceInfo
    //         })
    //         setValidateLoading(false)
    //         setStudentInfo(data?.data?.student_data)

    //         if (data?.data?.student_data) {
    //             // Identify sends an event, so you want may want to limit how often you call it
    //             posthog?.identify(data?.data?.student_data.student_id, {
    //                 email: data?.data?.student_data.email,
    //             })
    //         }

    //         posthog?.capture('click_student_login', {
    //             student_id: data?.data?.student_data?.student_id,
    //             timestamp: new Date().toISOString()
    //         })
    //         localStorage.setItem("student_data", JSON.stringify(data?.data?.student_data))
    //         localStorage.setItem("token", JSON.stringify(data?.data?.token))
    //         navigate('/')
    //     }
    //     catch (e) {
    //         if (e.response) {
    //             setOtpErrorMessage(e.response.data?.message)
    //             setShowOtpErrorMessage(true)
    //             handleErrorFromValidateApi(e.response.data?.message)
    //         }
    //         setValidateLoading(false)
    //     }
    // }

    const handleKeyDown = (event, handler) => {
        if (event.key === 'Enter') {
            if (emailRegex.test(email)) {
                handler();
            }
            else {
                setErrorMessage("Please enter a valid email")
                setShowErrorMessage(true)
            }
        }
    }

    useEffect(() => {
        setStudentInfo(null)
    }, [])

    return (
        <div className="login-with-email">

            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={alreadyLoggedInModal}
                onClose={() => setAlreadyLoggedInModal(false)}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <div className='fitgree-font text-[#050519] flex flex-col gap-4'>
                        <div className='text-[20px] font-semibold'>Multiple Sessions Detected</div>
                        <div className='text-base font-semibold'>Oops! It looks like your session is active elsewhere. For security reasons, currently only one session is allowed on an account at a time.</div>
                        <div className='text-base font-semibold'>Would you like to continue here or log-out to continue the session elsewhere? Don't worry, your session progress is safe either way!</div>
                        <button
                            className='font-semibold text-[#F5F5FF] bg-[#3838F1] w-fit rounded-md text-sm py-1 px-2 ml-auto'
                            onClick={() => {
                                logoutStudent()
                            }}
                        >
                            Continue Here
                        </button>
                    </div>
                </Sheet>
            </Modal>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="company-name" style={{ marginBottom: 6 }}>
                    <CompanyLogo2 />
                </div>
                <span className='powered-by-interleap'>powered by Interleap</span>
            </div>
            {
                showForgotComp ?
                    <div className='content'>
                        <div className='mb-5 font-semibold'>Forgot password?</div>
                        <div className='common-input-field' style={{ width: '100%' }}>
                            <Input
                                type='email'
                                value={email}
                                ref={emailRef}
                                onChange={(e) => {
                                    const lowerCaseEmail = e.target.value.toLowerCase();
                                    setEmail(lowerCaseEmail)
                                    setShowErrorMessage(false)
                                    setForgotMsg(false)
                                }}
                                // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                                placeholder='Email'
                                disabled={forgotPasswordLoading}
                            />
                            {showErrorMessage && <div className='error-message'>{errorMessage}</div>}
                            <div className='mt-[25px] w-[170px] mr-auto ml-auto'>
                                {
                                    forgotPasswordLoading ? (
                                        <button className='w-full bg-white text-[#050519] rounded font-semibold text-md'>
                                            <CircularProgress size={18} color="inherit" />
                                        </button>
                                    )
                                        :
                                        <button className='w-full bg-white text-[#050519] rounded font-semibold text-base p-[7px]' onClick={() => {
                                            if (emailRegex.test(email)) {
                                                forgotPassword()
                                            }
                                            else {
                                                setErrorMessage("Please enter a valid email")
                                                setShowErrorMessage(true)
                                            }
                                        }}>
                                            Send Email
                                        </button>
                                }
                            </div>
                            {forgotMsg && <div className="flex justify-center text-[12px] mt-3 text-green-500">{forgotMsg}</div>}
                        </div>
                    </div>
                    :
                    <div className='content'>
                        <div className='mb-5 font-semibold'>Enter You Email ID</div>
                        <div className='common-input-field' style={{ width: '100%' }}>
                            <Input
                                type='email'
                                value={email}
                                ref={emailRef}
                                onChange={(e) => {
                                    const lowerCaseEmail = e.target.value.toLowerCase();
                                    setEmail(lowerCaseEmail)
                                    setShowErrorMessage(false)
                                }}
                                // onKeyDown={(e) => handleKeyDown(e, sendOtpToEmail)}
                                placeholder='Email'
                                disabled={loading}
                            />
                            {showErrorMessage && <div className='error-message'>{errorMessage}</div>}
                        </div>
                        <div className='common-input-field' style={{ width: '100%', marginTop: 20 }}>
                            <Input
                                type='password'
                                value={password}
                                // ref={emailRef}
                                onChange={(e) => {
                                    setPassword(e.target.value)
                                    setShowPasswordErrorMessage(false)
                                }}
                                onKeyDown={(e) => handleKeyDown(e, checkStudentLogin)}
                                placeholder='Password'
                                disabled={loading}
                            />
                            {showPasswordErrorMessage && <div className='error-message'>{passwordErrorMessage}</div>}
                        </div>
                        <div className='mt-[25px] w-[120px]'>
                            {loading ? (
                                <button className='w-full bg-white text-[#050519] rounded font-semibold text-md'>
                                    <CircularProgress size={18} color="inherit" />
                                </button>
                            ) : (
                                <button
                                    className='w-full bg-white text-[#050519] rounded font-semibold text-base p-[7px]'
                                    onClick={() => {
                                        if (emailRegex.test(email)) {
                                            if (password !== "" && password) {
                                                checkStudentLogin()
                                            }
                                            else {
                                                setShowPasswordErrorMessage(true)
                                                setPasswordErrorMessage("Enter password")
                                            }
                                        }
                                        else {
                                            setErrorMessage("Please enter a valid email")
                                            setShowErrorMessage(true)
                                        }
                                    }}
                                >Login</button>
                            )}
                        </div>
                    </div>
            }
            {
                showForgotComp ? <button className='text-xs mt-auto mx-auto opacity-[0.9] font-semibold' onClick={() => {
                    setShowErrorMessage(false)
                    setShowForgotComp(false)
                    setForgotMsg(null)
                }}>
                    Back to login
                </button>
                    :
                    <button className='text-xs mt-auto mx-auto opacity-[0.9] font-semibold' onClick={() => {
                        setShowErrorMessage(false)
                        setShowForgotComp(true)
                        setForgotMsg(null)
                    }}>
                        {"Forget Password >"}
                    </button>
            }

            {/* {
                forgotPasswordLoading ?
                    <button className='text-xs mt-auto mx-auto opacity-[0.9] font-semibold'>
                        <CircularProgress size={18} color="inherit" />
                    </button>
                    :
                    <button className='text-xs mt-auto mx-auto opacity-[0.9] font-semibold' onClick={() => {
                        setShowForgotComp(true)
                    }}>
                        {
                            showForgotComp?"Back to login":"Forget Password >"
                        }
                    </button>
            } */}

        </div>
    );
}

export default LoginWithEmail;